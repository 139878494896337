
import { defineComponent, ref } from 'vue';

declare var XLSX:any;
declare var xtos: any ;
declare var stox:any;

import  SerialConnection from "../../components/SerialConnection.vue";

import { Serial ,SerialUsbAutoConnectionDelegate} from "../../modules/hardware/serial";

import { Media } from "../../modules/hardware/media";
import {Geolocation , GeolocationCallback } from "../../modules/hardware/geolocation";
var circleDetectionWorker:any = null;
const piexif = require('piexifjs');

import { faFile } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

var detectCircleTimeoutID:any =false;
var clearCircleScaleTimerID:any=false;
// const Plugin  = require( "../../modules/app/plugin").Plugin;
// const cv  = require( "../../assets/opencv/opencv.js").cv;

const moment = require('moment');

// declare var cv: any;
// import XSpreadSheet  from "x-data-spreadsheet";

const cv = require('opencv.js');

const hov = 59.9484; //FOV 70 degree
//const hov = 69.84; //FOV 80 degree

const calibrationLongLengthMeterThreshold = 0.5 ;

var circleDetectionSem = false;
// var circles = new cv.Mat();
var selectedCircleNo = 0 ;

const excludeBothLengthMs = 68;

//zzt
let touch_distance = 0;
let touch_theta = 0;
let overlay_radius_i = 0;
let global_canvas: HTMLCanvasElement | null = null;
let dynamicRadiusPixel = 0;
let adjustedDynamicRadiusPixel = 0;
let overlayX = 0;
let overlayY = 0;
let screen_width = 0;

let CircleDetectionSetting :any  = {
    currentNum : 0 ,
    1: {
         dp: 1.1,
         minDist: 40,
         param1 : 45,
         param2: 40,
         minRadius : 30,
         maxRadius : 80
    },
    2: {
         dp: 1.2,
         minDist: 50,
         param1 : 80,
         param2: 45,
         minRadius : 80,
         maxRadius : 190
    },
    3: {
         dp: 1.3,
         minDist: 70,
         param1 : 60,
         param2: 50,
         minRadius : 190,  
         maxRadius : 400
    }
    /*,
    3: {
         dp: 0.9,
         minDist: 15,  //increae-> reduce nearby cycle to detect
         param1 : 55,  //decrease-> detect more edge, 50> detect only selective edges, 
         param2: 20,
         minRadius : 190,
         maxRadius : 350
    } */
    
};

/*
let CircleDetectionSetting :any  = {
    currentNum : 2 ,
    1: {
         dp: 1,
         minDist: 15,
         param1 : 50,
         param2: 20,
         minRadius : 25,
         maxRadius : 55
    },
    2: {
         dp: 1,
         minDist: 25,
         param1 : 50,
         param2: 20,
         minRadius : 70,
         maxRadius : 110
    },
    3: {
         dp: 1,
         minDist: 50,
         param1 : 50,
         param2: 20,
         minRadius : 95,
         maxRadius : 150
    } 
    
};

*/

function downloadBase64Image(linkSource:string, fileName:string) {
     // const linkSource = `data:${contentType};base64,${base64Data}`;
     const downloadLink:any = document.createElement("a");
     downloadLink.href = linkSource;
     downloadLink.download = fileName;
     downloadLink.click();
}
    
function degToDmsRational(degFloat :any ) {
    var minFloat = degFloat % 1 * 60
    var secFloat = minFloat % 1 * 60
    var deg = Math.floor(degFloat)
    var min = Math.floor(minFloat)
    var sec = Math.round(secFloat * 100)

    deg = Math.abs(deg) * 1
    min = Math.abs(min) * 1
    sec = Math.abs(sec) * 1
  
    return [[deg, 1], [min, 1], [sec, 100]]
  }

function convertSensorDataToArr (tex:any){
        
        const arr = [];
        const texs =tex.split('\r\n');
        for ( var i=0 ; i < texs.length; i++ ){
                    const text = texs[i];
            
                    if(text.indexOf('E') !== -1) { continue; }    
               
                    const result = text.match(/D=(.*)m,/);
                 
                    if (result != null && result.length != 0 ) {  
        
                        arr.push( Number(result[1]) );
                    }
                  }
             return arr;
}


       // cv.HoughCircles(gray,circles,cv.HOUGH_GRADIENT,1,20,  50, 30,40,80);
const white = new cv.Scalar(255, 225, 255,100);
const nextCirclecol = new cv.Scalar(255, 174, 185,100);  //zzt for overlap circle
const green = new cv.Scalar(100, 225, 205,100);

interface ICalibration {
  serial1Value:      number;
  serial2Value:      number;
  serial3Value:      number;
  add:      Function;
  ratio : Function;
}
 

const updateCanvas = document.createElement('canvas');
const updateSubCanvas = document.createElement('canvas');

export default {
  name: "BoreholeApp" ,
  
  data(): any {
          return {
            
            selectedCircleDetectionMode: CircleDetectionSetting.currentNum,
            skipWindowRate: 30, 
             detectionModeList : [
              
              { value : "length" , title: "削孔長" },
              { value : "circle,length" , title: "削孔径,削孔長" } 
              ] ,
           selectedDetectionMode:  "circle,length" ,
            circleImage : new Image() ,
            
            measurementTimerMs : 2000,
            detectCircleIntervalMs : 1000,  
            nonOverlapCircleAreaEnabled : false , 
            isLoading : false,
            realData1 : 0,
            realData2 : 0,
            realData3 : 0,
            
            scaleRealWorldDiameter: 4.0 ,
            isGpsUsed :false,
            showApp :0 ,
            clearConnection:false,
            selectedSubCameraDevice : undefined,
            selectedMainCameraDevice : undefined,
            useSubCamera:false,
        cameraDeviceIds : [] ,
        denshiShoKokuban : {
            builder : "" ,
            projectName : "", 
            projectType : "" ,
            site :"",
            status : "" ,
            isNecessary : true 
        },
              hasCorrectionFile : false , 
              isMeasuring : false ,
              isMeasurementStopped : false,          
              recentlyAcquiredData : [ ] ,
              recentlyAcquiredDataMax : 20 ,
  
             // acquiredData : [  ] ,
              directoryHandle : false , 
              enableDiameter : false , 
              isDebugMode : false , 
               debugModeCount : 0 ,
               calibrationA : -8.97142857,
               calibrationB:  1.202857143,
              // calibrationLongLengthMeter : 0.544 , 
                 calibrationLongLengthMeter : 0.750, 
               calibrationShortLengthMeter  : 0.088 ,
    
         testSettingVisble  : false,
         testCircleDetectionSetting : {
         dp: 1,
         minDist: 20,
         param1 : 50,
         param2: 30,
         minRadius : 40,
         maxRadius : 80
         },
         
          geolocation : undefined , 
          gps : { lat : 0 , lon : 0},
          calibrationThresholdShortMeasurementsCentimeter : 65 ,
          calibrationCorrectionShortMeasurementsMillimetre : 9 ,
          
          calibration : {
            isLowerThanBothLengthChecked : true ,
            use : "add",
            serial1Value : 0,
            serial2Value : 0,
            serial3Value : 0
          } as any ,
       
        objectDetectingSetting : { area : { y : { top : 0.2, bottom: 0.25  } ,  x : { left : 0.2, right: 0.2  }  }},
         faFile :faFile,
        objectDetectingPoint : { start : { x:0 ,y:0 } , end : { x:0,y:0} } ,
        serialIntervalTaskFunc : undefined ,
        filename : "" , 
        
       SpreadSheetKey : 0 , 
       avgLength : 0,
       fullLength : 0 ,
        nonOverlapCircleArea : 0 ,
       diameter : 0 ,  
       DiameterCorrectionValue : 
       { detectStartPoint : 68  , coefficient :  1   },
         LengthCorrectionValue : {
            side: { sub: 0 } ,
            center : { thr : 830 , add : { above: 0 ,  below: 0 } }
        },
        cameraRate:  { ideal: 10, max: 14},
       selectCircleMode : false , 
       detectCircleIntervalID : undefined , 
       detectCircleIntervalClear : false ,
       
       currentCircle : { center: 0 , radius: 0 } , 
        circles : undefined , 
       serial1Value: 0 ,
       serial2Value: 0 ,
       serial3Value: 0 ,
       
       serial1Error : "",
       serial2Error : "",
       serial3Error : "",
       
         viewState:  {
            isCanvasShow :false,
            isExcelShow :true,
            isSettingShow :false,
            isExcelFileMenuShow : false,
            isExcelSelectCellMenuShow:false,
            selectCellMode : false ,
            fullLengthCellSelected : false ,
            diameterCellSelected: false ,
            gpsCellSelected : false,
            DateTimeCellSelected:false,
            RealData1CellSelected:false,
            RealData2CellSelected:false,
            RealData3CellSelected:false,
            }, 
         cameraInput :undefined,
         cameraOutput: undefined ,
         serial1 : undefined ,
         serial2 : undefined ,
         serial3 : undefined ,
         xlsxUpdated : false , 

         spreadsheet : null ,
         xSpreadSheetOption : { 
             showToolbar: false,
             showGrid: true,
                showContextmenu: false,
            },  
         xSpreadSheetData :[{}] ,
        
         selectedFullLengthCell: { 'row': -1 , 'col': -1 },
         selectedDiameterCell: { 'row': -1 , 'col': -1 },
         selectedGPSCell: { 'row': -1 , 'col': -1 },
        
         selectedDateTimeCell: { 'row': -1 , 'col': -1 },
         selectedRealData1Cell: { 'row': -1 , 'col': -1 },
         selectedRealData2Cell: { 'row': -1 , 'col': -1 },
         selectedRealData3Cell: { 'row': -1 , 'col': -1 },
         media1 : undefined ,
         mediaSubCamera : undefined,
         videoWidth:0,
         videoHeight:0 ,
         colWidth : 0 ,
         rowHeight : 0,
         
         canvas2dCtx : undefined,
         presetVideo: undefined ,
         previousFocusPosition : {x:0,y:0},
         
      }

  },
  components:  { SerialConnection } ,
  
  watch: {
    viewState: {
      async handler(this:any,newVal:any) {

        if (this.viewState.isCanvasShow) {
        
          this.showLoadingView();
          this.$nextTick(async () => {
         
            this.removeExcel(); 
            await this.startMainCamera();
            await this.startSubCamera();
           
            this.isLoading=false;
          });
             // await this.$refs.cameraConnection.open();  
        }
        if(this.viewState.isSettingShow ){
          
          this.showLoadingView();
          this.$nextTick(async () => {
              
              await this.connectSerial();
              this.isLoading=false;
          });
        }
        if (this.viewState.isExcelShow) {
          
          this.showLoadingView();
          this.$nextTick(() => {
                
                this.createExcel(); 

                this.isLoading=false;
              // this.startMainCamera();
            // ここで必要な処理を実行
          });
        }
      
      },
      deep: true
    }
  },
  beforeUnmount(this: any ) {
  
    this.closeSerial();
    (window as any).document.removeEventListener('focusin', this.handleFocusEvent );
 
   /*
 console.log("beforeUnmount");
        window.removeEventListener('message', this.outsideClickEvent);

    var spreadsheetArea = document.getElementById("spreadsheet-area");
    var xSpreadsheet = document.getElementById("x-spreadsheet");
    if (xSpreadsheet) {
      if(spreadsheetArea){
        spreadsheetArea.removeChild(xSpreadsheet);
      }
    }
*/

  },
async  mounted (this:any){
//    console.log("mounted");
    (window as any).document.addEventListener('focusin', this.handleFocusEvent   , true); 

     if ( this.$route.query.show == "canvas" ){

      this.$nextTick( () => {
           setTimeout(async ()=>{
           await this.showArea("Canvas");   
           },1500);
        });   
      }
      this.createExcel(); 
        
    
      await this.initSettings();
      
      if( this.isMeasuring) { 
            await this.connectSerial();
            await this.measurement();
      }
    // Set  to window width //zzt_240909  //1413
    //screen_width = window.innerWidth;
    //screen_width = window.innerHeight;
    //alert(screen_width);

       
  },
  beforeMount :async function( this: any ){
       //  console.log("beforeMount");
this.circleImage =
      await (async (src:any)=> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (e) => reject(e);
    img.src = src;
  })})("/pwa/img/overlay_circle.png");

  this.initCircleDetectionWorker();
  }
  ,updated(this:any){
    if ( this.xlsxUpdated ) {
        this.setXlsxFunc();
        this.xlsxUpdated = false ;
    }
  }
  , created(){} 
  ,methods:{

  setOverlayRadius(value:any) {
      overlay_radius_i = value;
      
      if (overlay_radius_i !== 0) {   // Increase or decrease the dynamic radius 
        if (Math.sign(overlay_radius_i) === 1) {  // Positive (up button pressed)
          dynamicRadiusPixel += overlay_radius_i;  // Increase radius
        } else {  // Negative (down button pressed)
          dynamicRadiusPixel -= Math.abs(overlay_radius_i);  // Decrease radius
        }
      }//if
      //draw again with new radius
      adjustedDynamicRadiusPixel =  dynamicRadiusPixel;
      //const radiusMM = this.findRealWorldRadius(adjustedDynamicRadiusPixel); // Calculate pixel radius
      //const converted_rad = 2.0 * radiusMM ;
      if (global_canvas) {
        this.drawCircleImage(overlayX, overlayY, adjustedDynamicRadiusPixel, global_canvas);
      }//if
    },

  removeDetectedCircle(this:any){
     this.circles=undefined;
     this.diameter=0;
     selectedCircleNo  = -1;   
     const cw = this.cameraOutput.width/2;
     const ch = this.cameraOutput.height/2;

     const dispCircleCtx =   this.$refs.dispCircleCanvas.getContext('2d');
     dispCircleCtx.clearRect(0, 0, cw, ch);
     this.selectCircleMode=false;
  },
  handleFocusEvent( this:any, event: FocusEvent) {

       
        if(this.viewState.showExcelMenu ){
        const target = event.target as HTMLElement;
        const position = target.getBoundingClientRect();
        

        if (target.tagName === 'INPUT' && target.className=='' ){
        
          if(this.viewState.selectCellMode){
            
            event.preventDefault(); 
            (event.target as HTMLElement).blur();
            return;
          }

          if(this.previousFocusPosition.x ==position.x  && this.previousFocusPosition.y ==position.y ){
        
            this.previousFocusPosition=position;
             
            return;
          }else {
              event.preventDefault(); 
              (event.target as HTMLElement).blur();
            this.previousFocusPosition=position;
            // (event.target as HTMLElement).blur();
            return;
          }
        }
          
        }
      // }
  },
    showLoadingView(this:any,timeout=8000){

      this.isLoading=true;
      setTimeout( ()=>{ this.isLoading=false; },timeout);   

    },
    async closeSerial(this:any){
    
    if(this.serial1){
    try{
      await this.serial1?.releaseReaderLock();  
      await this.serial1.close();
    }catch(e){ console.error(e);}
    }
    if(this.serial2){
     try{
      await this.serial2?.releaseReaderLock();
      await this.serial2.close();
    }catch(e){ console.error(e);}
    }
    if(this.serial3){
     try{

      await this.serial3?.releaseReaderLock();
      await this.serial3.close();
    }catch(e){ console.error(e);}
    }
    },
    async connectSerial(this:any){
     await this.closeSerial();
    if( !this.serial1){
        try{
        
        this.serial1 = new Serial( { baudRate:38400 , name : "serial1" , filter : { "usbProductId": 24577, "usbVendorId": 1027 } } );
        
        }catch (e){ 
          console.error(e);


        }
      }
      if(!this.serial2){
        try{
        
        this.serial2 = new Serial( { baudRate:38400 , name : "serial2" , filter : { "usbProductId": 24577, "usbVendorId": 1027 } } );
     
        }catch (e){ 
          console.error(e);

        }
      }
      if(!this.serial3){
      try{
      this.serial3 = new Serial( { baudRate:38400 , name : "serial3" , filter : { "usbProductId": 24577, "usbVendorId": 1027 } }  );
      }catch (e){ 
       
        console.error(e);
        }
      }

      var con = new SerialUsbAutoConnectionDelegate(); 
       
      con.push(this.serial1);
      con.push(this.serial2);
      con.push(this.serial3);      
      con.enable();
      
     await con.connectIfConnectionExists();


    },
    removeExcel(this:any){
  
       (document.getElementById("x-spreadsheet") as any).innerHTML = '';
        this.viewState.isExcelShow=false;
    },
    createExcel(this:any){
 
      if( (document.getElementById("x-spreadsheet") as any ).hasChildNodes() ) {
//        console.log("spreadsheet destory");

        (document.getElementById("x-spreadsheet") as any).innerHTML = '';
      }
      
      this.spreadsheet = 
        ( this.xSpreadSheetOption)?  (window as any).x_spreadsheet('#x-spreadsheet',this.xSpreadSheetOption)
       
       .loadData(this.xSpreadSheetData) :
        (window as any). x_spreadsheet( "#x-spreadsheet").loadData(  this.xSpreadSheetData );
        this.setXlsxFunc();
        this.xlsxUpdated = false ;
   
      
    },
async startSubCamera(this:any){
  if (this.selectedSubCameraDevice){
    
    window.localStorage.setItem('boreholeSubCamera', JSON.stringify(this.selectedSubCameraDevice) );
    
    try{
    this.mediaSubCamera =new Media({
          audio: false,
          video : {  
            frameRate: this.cameraRate,
             width: 1280, height: 800,
            // facingMode:  "user" ,
           // facingMode: { exact: "environment" },
             deviceId  :  this.selectedSubCameraDevice.deviceId
             }  
          } 
       ); 
    }catch (e:any){
            this.mediaSubCamera =new Media({
          audio: false,
          video : {  
            frameRate: this.cameraRate,
             width: 1280, height: 800,
            // facingMode:  "user" ,
           // facingMode: { exact: "environment" },
          //   deviceId  :  this.selectedSubCameraDevice.deviceId
             }  
          } 
       );
    }
       await this.mediaSubCamera?.open();
       this.$refs.videoSubCamera.srcObject = this.mediaSubCamera?.stream;
        this.$refs.videoSubCamera.addEventListener("loadedmetadata", () => {
               this.$refs.videoSubCamera.play();    
        });
        
        
  }
},
async initSettings(this: any){
      
      if (  this.$route.query.use && this.$route.query.use.includes ("subcamera") ){
          this.useSubCamera=true;      
      }
      if ( this.$route.query.use  && this.$route.query.use.includes ( "nonoverlapcirclearea" )){

          this.nonOverlapCircleAreaEnabled=true;      
      } 

   this.canvas2dCtx = this.$refs.canvas.getContext("2d");
      this.cameraInput = this.$refs.canvas;
      this.cameraOutput = this.$refs.canvasOutput;
      this.dispCanvas = this.$refs.dispCanvas;

   const mediaDevices :any = await window.navigator.mediaDevices.enumerateDevices();

  const boreholeSubCamera:any = (():any => { try {  return JSON.parse(window.localStorage.getItem('boreholeSubCamera') as any ); } catch (e) { return {}; } })();
 
 const boreholeMainCamera:any = (():any => { try {  return JSON.parse(window.localStorage.getItem('boreholeMainCamera') as any ); } catch (e) { return {}; } })();
 
    for (let len = mediaDevices.length, i = 0; i < len; i++) {
      const item = mediaDevices[i];
      if (item.kind === "videoinput") {
        
        const deviceId = item.deviceId;
        const label = item.label;
   
      if (boreholeSubCamera && boreholeSubCamera .deviceId == deviceId ) {
    
        this.selectedSubCameraDevice = boreholeSubCamera;
      }

      if ( boreholeMainCamera && boreholeMainCamera .deviceId == deviceId ) {
        
        this.selectedMainCameraDevice = boreholeMainCamera;
      }

      this.cameraDeviceIds.push({ deviceId, label });
    }
  }
  
  if ( !this.selectedMainCameraDevice ){
   
    this.selectedMainCameraDevice = this.cameraDeviceIds[0];  
  }
    
//   this.startMainCamera();

    // this.removeCalibData();
      
    window.document.addEventListener('click',this.outsideClickEvent );
      
   
      this.loadCalib();
      this.loadCurrentData();
   
      this.calibration. add = function( k:any,v:number) :number {
                const key:  keyof ICalibration = k;
               // console.log(key);
                const va = this[key] as number;

                    return  v + va  ;
            } ;
       this.calibration.ratio = (k:any ,v:number) :number => {
                       
                       const key:  keyof ICalibration = k;
                       const va = this[key] as number;
                       return  v / va  ;
                       
            } ;
      if(this.isGpsUsed){
        this.geolocation = new Geolocation (
            new class implements GeolocationCallback {
               public gps :any;
                constructor (gps:any){ this. gps = gps ; } 
                geolocationReceived = ( pos :any ):void => {
                     this.gps.lat = pos.coords.latitude;
                     this.gps.lon = pos.coords.longitude;
                }
                geolocationFailed = ( error :any ):void =>  {
                    console.error(error);
                    
                } 
            }(this.gps)  
        );
      }
      
      this. xlsxUpdated = true;
      this.$emit('show-header');
      //9600/19200/38400/115200, default 38400
      
      await this.connectSerial();

    // this.startCircleDetection();

    // this.startMainCamera();
     //this.startSubCamera();


},
initCircleDetectionWorker(this:any){
    
    console.log("init");
    if (null !== detectCircleTimeoutID ){ 
      clearTimeout( detectCircleTimeoutID  );
      detectCircleTimeoutID=null;

     }       
    if (circleDetectionWorker!==null) {
            
        circleDetectionWorker.terminate();
        console.log('Worker terminated');
        circleDetectionWorker=null;
     }
     circleDetectionWorker = new Worker('../../modules/app/opencv/workers/detectcircle.js', { type: 'module' });
     
     circleDetectionWorker.onerror = (e:any)=> {
      
      console.log(e);
      this.initCircleDetectionWorker();
    }
    
    circleDetectionWorker.onmessage =(d :any ) => {
        
        circleDetectionSem = false;
         console.log("detected!");
         
         console.log(d);

        if ( !d.data.error ){
              try{
                    if( !this.selectCircleMode){ //update the circle if selectCircleMode is false
                        
                        if (
                            d.data.data &&
                            d.data.data.circles &&
                            d.data.data.circles.rows
                        ){
                            this.circles = d.data.data.circles;    
                            selectedCircleNo  = -1;   
                            this.selectCircle(false);
                        }
                    }
                     
                }catch(e){ 
                  
                  this.initCircleDetectionWorker();
                  console.error(e);
                  //this.startCircleDetection();
                }
          } else {
               console.error(d);
              this.initCircleDetectionWorker();
          }
    }

     circleDetectionSem=false; 
},
  detectCircle(this:any){
               
        try{
        /*if( !this.selectedDetectionMode.includes('auto') ) { 
          circleDetectionSem=false;}*/
        
          if( !circleDetectionSem ) { //no other circle detecton in the process, //Sends a new request to the worker for circle detection
          console.log("detecting start.");
          circleDetectionSem = true;
            const para = JSON.parse(JSON.stringify(this.testCircleDetectionSetting)) ;
            
         // console.error("*Testing", this.colWidth, " this.rowHeight ", this.rowHeight);
        //---------------------^------------Original code end 
         if (this.videoWidth ===0 ){return; }
           this.colWidth   = this. videoWidth  * 1/3;   //start pt
            this.rowHeight   = this. videoHeight * 1/4;

        //visuslize image_zzt
            const imgData = this.canvas2dCtx.getImageData(0, 0, this.cameraInput.width, this.cameraInput.height);
            const h = this.videoHeight - this.rowHeight 
            const w = this.colWidth 
              // const imgData = this.canvas2dCtx.getImageData(0, 0, width, height);
            //this.canvas2dCtx.putImageData(imgData, 0, 0); 
            
             if (null !== detectCircleTimeoutID ){ 
              clearTimeout( detectCircleTimeoutID  ); 
              detectCircleTimeoutID=null;
            }
            
            detectCircleTimeoutID = setTimeout(()=>{ this.initCircleDetectionWorker(); } ,6000);
            
//this.canvas2dCtx.putImageData(imgData, 0, 0); 
            circleDetectionWorker.postMessage({
                type : "detect",
                imgData : imgData,
                crop : { rect :   new cv.Rect (  this.colWidth,  this.rowHeight , w , h ) }, 
                setting :
                    (this.isDebugMode) ?
                     { 
                    dp: Number(para.dp),
                    minDist: Number(para.minDist),
                    param1 : Number(para.param1),
                    param2: Number(para.param2),
                    minRadius : Number(para.minRadius),
                    maxRadius : Number(para.maxRadius)
                } : CircleDetectionSetting[ CircleDetectionSetting.currentNum ]
       });         // setTimeout(()=>{ circleDetectionSem=false; },500)
                 //crop : { rect :   new cv.Rect (  this.colWidth,  this.rowHeight   , this.colWidth*1.5 , this.rowHeight * 3   ) },   
                 //crop : { rect :   new cv.Rect (  this.colWidth,  this.rowHeight   , this.colWidth*2 , this.rowHeight * 3   ) },      //org
       }
        } catch (e:any){
         // console.log(e);
           circleDetectionSem = false;
           }
    },

startCircleDetection(this:any){

    // manual mode
    if (!this.selectedDetectionMode.includes ("circle") ){ return ; }
    if (!this.selectedDetectionMode.includes ("auto") ){ 
        
        this.detectCircle();
        return ; 
    } 
    this.detectCircleIntervalID = setInterval( ()=>{
                
                if (this. videoWidth ==0){
                  return;
                }
                try{
                
               // alert(this.selectCircleMode);
                 if( !this.selectCircleMode){
                     
             //        console.log(this.gps);
                       // this.colWidth   = this. videoWidth  * 1/3;   //start pt
                       // this.rowHeight   = this. videoHeight * 1/4;
                      
                          this.colWidth   = this. videoWidth  * 1/3;   //start pt
                        this.rowHeight   = this. videoHeight * 1/4;
                    
                      if(this.selectedDetectionMode.includes("circle") ){
                           this.detectCircle();  
                         
                       }
                        
                    }   
                }catch(e:any){

                  console.error(e);
                }
                 // this.$refs.canvas.style.display = "none";
            }  ,this.detectCircleIntervalMs);
            
   
},

async uploadSubCameraImage(this:any,basefilename:string){

      // this.$refsを使ってビデオ要素にアクセス
      const videoElement:any = this.$refs.videoSubCamera;
      if (!videoElement) {
        console.error('ビデオ要素が見つかりません。');
        return;
      }

      // キャンバスを作成し、ビデオのフレームを描画
      const canvas:any = document.createElement('canvas');
      canvas.width = videoElement.videoWidth;
     
      canvas.height = videoElement.videoHeight;
      const context:any = canvas.getContext('2d');
      context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      context.drawImage( this.$refs.denshiShoKokubanCanvas , canvas.width-160, canvas.height-180, this.$refs.denshiShoKokubanCanvas.width ,this.$refs.denshiShoKokubanCanvas.height);
      const base64img:any=context.canvas.toDataURL('image/jpeg', 1.0);
      this.uploadBase64Image(base64img, basefilename+".jpg" );
  },
async changeMainCamera(this:any){
/*
 window.localStorage.setItem('boreholeMainCamera', JSON.stringify(this.selectedMainCameraDevice) );
 this.$router.push({ path: this.$route.path, query: { use : "subcamera", show: 'canvas' }});
 this.$router.go(0);
 */

  this.startMainCamera();

},
async startMainCamera(this:any){

  // this.clearConnection=false;
  window.localStorage.setItem('boreholeMainCamera', JSON.stringify(this.selectedMainCameraDevice) );

  navigator.mediaDevices.enumerateDevices()
    .then(function(devices) { // 成功時
      devices.forEach(function(device) {
  // デバイスごとの処理
   // console.log(device);
      });
  }).catch(function(err) { // エラー発生時
  
    console.error('enumerateDevide ERROR:', err);
  });

    try{
      this.media1= new Media({
          video : {   
            frameRate: this.cameraRate,
               width: 1280, height: 800,
            //   facingMode: { exact: "environment" },
              deviceId: this.selectedMainCameraDevice.deviceId,
          
          }, 
          audio  :   false  ,
          secondary: {
             video :{
               width: 1280, height: 800,
              //  facingMode: "user",
                deviceId: this.selectedMainCameraDevice.deviceId   
              } ,audio :false
          } 
      });
    }catch (e:any){

        this.media1= new Media({
          video : {   
            frameRate: this.cameraRate,
               width: 1280, height: 800,
            //   facingMode: { exact: "environment" },
          
          }, 
          audio  :   false   
      }); 

    }
      
      const video = this.$refs.videoMainCamera;
            await this.media1.open();
       this.$refs.videoMainCamera.srcObject = this.media1?.stream;
        this.$refs.videoMainCamera.addEventListener("loadedmetadata", () => {
           this.$refs.videoMainCamera.play();    
        });
    
   //   this.presetVideo = (video:any)=>{
              
              

               // video.style.display = "none";
            this.$refs.canvas.style.display = "none";
              
            if(  this.selectedDetectionMode.includes('auto') ) { 
                this.startCircleDetection();
            }
             
             const dispCtx =   this.$refs.dispCanvas.getContext('2d');

// for circle detection.
                    
const width = dispCtx.canvas.width;
const height = dispCtx.canvas.height;

// 新しいCanvasを作成
updateCanvas.width = width;
updateCanvas.height = height;

updateSubCanvas.width = width;
updateSubCanvas.height = height;


const denshiShoKokubanCtx:any = this.$refs.denshiShoKokubanCanvas.getContext('2d');

var skip = this.skipWindowRate;
             video.addEventListener('play', () => {
              
                        const func = ()=>{ 
                            if( 1===(skip--) ){
                                skip=this.skipWindowRate;
                                 window.requestAnimationFrame(func);
                                return;
                            }
                           //zzt
    if(this.isGpsUsed){
                         this.geolocation.getCurrentPosition();
                      }
                  
const sx = 0; // video.videoWidth * 1/3 * 0.5; 
const sy = 0; //video.videoHeight * 1/3  * 0.5 ; 

const sWidth = video.videoWidth; 
const sHeight = video.videoHeight 

const h = video.videoHeight - this.rowHeight 
const w = this.colWidth 

this.videoWidth = video.videoWidth;
this.videoHeight = video.videoHeight; 

this.canvas2dCtx.drawImage(
    video, 
    sx, sy, sWidth, sHeight,
    sx, sy, sWidth, sHeight
);


 dispCtx.drawImage(
    video, 
    sx, sy, sWidth, sHeight,
    0, 0, sWidth/2, sHeight/2
);


if ( this.denshiShoKokuban.isNecessary ){

  
  // テキストのスタイルを設定
  denshiShoKokubanCtx.fillStyle = '#3a5f47'; // 黒板風の色
  denshiShoKokubanCtx.fillRect(0, 0, 160, 180);

  // フォントサイズを14pxに設定
  denshiShoKokubanCtx.font = '12px Arial';
  denshiShoKokubanCtx.fillStyle = 'white';

  // 各テキストをキャンバスの左上隅に配置
  const builder = this.denshiShoKokuban.builder;
  const projectName = this.denshiShoKokuban.projectName;
  const projectType = this.denshiShoKokuban.projectType;
  const site = this.denshiShoKokuban.site;
  const status = this.denshiShoKokuban.status;
  const len = this.ceilDemicalPoint ( this.fullLength  ,3 ) +'mm';
  const dia = this.ceilDemicalPoint( this.diameter ,1)+'mm';

  denshiShoKokubanCtx.fillText('1 施工者：'+builder, 10, 20);
  denshiShoKokubanCtx.fillText('2 工事名：'+ projectName, 10, 40);
  denshiShoKokubanCtx.fillText('3 工種：'+ projectType, 10, 60);
  denshiShoKokubanCtx.fillText('4 施工箇所名：'+site, 10, 80);
  denshiShoKokubanCtx.fillText('5 状況：'+status, 10, 100);
  denshiShoKokubanCtx.fillText('6 削孔長：'+ len , 10, 120);
  denshiShoKokubanCtx.fillText('7 削孔径：'+dia, 10, 140);

/*
  this.canvas2dCtx.fillStyle = '#3a5f47'; // 黒板風の色
  this.canvas2dCtx.fillRect(0, 0, 160, 180);

  // フォントサイズを14pxに設定
  this.canvas2dCtx.font = '12px Arial';
  this.canvas2dCtx.fillStyle = 'white';

  this.canvas2dCtx.fillText('1 施工者：'+builder, 10, 20);
  this.canvas2dCtx.fillText('2 工事名：'+ projectName, 10, 40);
  this.canvas2dCtx.fillText('3 工種：'+ projectType, 10, 60);
  this.canvas2dCtx.fillText('4 施工箇所名：'+site, 10, 80);
  this.canvas2dCtx.fillText('5 状況：'+status, 10, 100);
  this.canvas2dCtx.fillText('6 削孔長：'+ len , 10, 120);
  this.canvas2dCtx.fillText('7 削孔径：'+dia, 10, 140);
  */
}
                           /*  this.videoWidth = video.videoWidth;
                            this.videoHeight = video.videoHeight; 

                          this.canvas2dCtx.drawImage(  
                                video, 0,0 , 
                                video.videoWidth ,
                                video.videoHeight 
                            );  */
                            if (!this.detectCircleIntervalClear){
                               window.requestAnimationFrame(func);
                            }
                        }
                          if (!this.detectCircleIntervalClear){
                         window.requestAnimationFrame(func);
                          }
                   },true ); 
            
          //  };  
            /*
            this.cameraOutput .addEventListener('touchstart', (event: TouchEvent) => {  //zzt 240620
            event.preventDefault(); // Prevents default behavior like scrolling
            console.log("touch event!");
            this.touchSetDetectingPoint(this.cameraOutput , event);
            }); */

            
  },

      readCorrectionFile(this :any , e:any) {
         // console.error(e);
        
      const file = e.target.files[0]
      const reader:any = new FileReader()
     this.removeCalibData();
      reader.onload = ()=> {
         try{
           const data=JSON.parse(  atob( reader.result)  );
           
           if (  data.calibrationA ) { this.calibrationA = data.calibrationA ;}
           if ( data.calibrationB ) {  this.calibrationB = data.calibrationB;  }
            if ( data.calibrationLongLengthMeter ){ this.calibrationLongLengthMeter = data.calibrationLongLengthMeter; }
            if ( data.calibrationShortLengthMeter  ) { this.calibrationShortLengthMeter = data.calibrationShortLengthMeter; }
            if ( data.calibrationThresholdShortMeasurementsCentimeter  ) { this.calibrationThresholdShortMeasurementsCentimeter = data.calibrationThresholdShortMeasurementsCentimeter; }
            if (  data.calibrationCorrectionShortMeasurementsMillimetre ) { this.calibrationCorrectionShortMeasurementsMillimetre= data.calibrationCorrectionShortMeasurementsMillimetre;}
            if ( data.DiameterCorrectionValue ) {  this.DiameterCorrectionValue = data.DiameterCorrectionValue ;} 
            if ( data.calibration ) {  this.calibration = data.calibration; }    
            if ( data.testCircleDetectionSetting ){  this.testCircleDetectionSetting = data.testCircleDetectionSetting ; }
      

            this.hasCorrectionFile = true;
            this.saveCalib();
          }catch (e){ 
            console.error(e);
          }
      }
      reader.readAsText( file )
    },
      makeCorrectionFile (this:any){
          this.saveCalib();
         const dataStr= ( window as any) . localStorage.getItem("CalibData") ;  
         
           let str = btoa(dataStr);
        let ary = str.split(''); 
        let blob = new Blob(ary,{type:"text/plan"}); 
        let link = document.createElement('a');
        link.href = URL.createObjectURL(blob); 
        link.download = 'CorrectionFile.paletteiot'; 
        link.click(); 
        
      },
      removeCalibData (this:any){
          
            ( window as any) . localStorage.removeItem("CalibData");
      },
     // localStorage.clear();
      removeCurrentData (this:any){
          
           ( window as any) . localStorage.removeItem("RecentData");
      },
     loadCalib(this:any){
         try{
          const data=JSON.parse( ( window as any) . localStorage.getItem("CalibData") );
           if (  data.calibrationA ) { this.calibrationA = data.calibrationA ;}
           if ( data.calibrationB ) {  this.calibrationB = data.calibrationB;  }
            if ( data.calibrationLongLengthMeter ){ this.calibrationLongLengthMeter = data.calibrationLongLengthMeter; }
            if ( data.calibrationShortLengthMeter  ) { this.calibrationShortLengthMeter = data.calibrationShortLengthMeter; }
            if ( data.calibrationThresholdShortMeasurementsCentimeter  ) { this.calibrationThresholdShortMeasurementsCentimeter = data.calibrationThresholdShortMeasurementsCentimeter; }
            if (  data.calibrationCorrectionShortMeasurementsMillimetre ) { this.calibrationCorrectionShortMeasurementsMillimetre= data.calibrationCorrectionShortMeasurementsMillimetre;}
            if ( data.DiameterCorrectionValue ) {  this.DiameterCorrectionValue = data.DiameterCorrectionValue ;} 
         //  console.log(data);
            if ( data.calibration ) {  
             
              this.calibration.serial1Value = data.calibration.serial1Value;
              this.calibration.serial2Value = data.calibration.serial2Value;
              this.calibration.serial3Value = data.calibration.serial3Value; 
            }  
            
            if(data.cameraRate ){ this.cameraRate = data.cameraRate; } 
            if(data.measurementTimerMs ){ this.measurementTimerMs = data.measurementTimerMs; } 
            if(data.detectCircleIntervalMs ){ this.detectCircleIntervalMs = data.detectCircleIntervalMs; } 
            
            if ( data.testCircleDetectionSetting ){  this.testCircleDetectionSetting = data.testCircleDetectionSetting ; } 
            if (data.hasCorrectionFile) { this.hasCorrectionFile=data.hasCorrectionFile; }


        }catch (e:any){ 
            //console.error(e);
             }
     },

     
     saveCalib(this:any){
      
      ( window as any) . localStorage.setItem(
        "CalibData" ,
        JSON.stringify( {
            hasCorrectionFile : this.hasCorrectionFile,
            calibrationA : this.calibrationA ,
            calibrationB : this.calibrationB ,
            calibrationLongLengthMeter:  this.calibrationLongLengthMeter ,
            calibrationShortLengthMeter:  this.calibrationShortLengthMeter ,
            calibrationThresholdShortMeasurementsCentimeter: this.calibrationThresholdShortMeasurementsCentimeter ,
            calibrationCorrectionShortMeasurementsMillimetre: this.calibrationCorrectionShortMeasurementsMillimetre ,
            DiameterCorrectionValue: this.DiameterCorrectionValue  ,
            calibration : this.calibration , 
            testCircleDetectionSetting : this.testCircleDetectionSetting,
            cameraRate : this.cameraRate , 
            measurementTimerMs : this.measurementTimerMs,
            detectCircleIntervalMs : this.detectCircleIntervalMs
           
           
        }));
        
     },

      wipeCurrentData(this:any){
    
          this.recentlyAcquiredData=[];
          this.isMeasuring=false;
          this.filename ="";
          this.selectedFullLengthCell= { 'row': -1 , 'col': -1 };
          this.selectedDiameterCell= { 'row': -1 , 'col': -1 };
          this.selectedGPSCell= { 'row': -1 , 'col': -1 };
        
          this. selectedDateTimeCell= { 'row': -1 , 'col': -1 };
          this.selectedRealData1Cell= { 'row': -1 , 'col': -1 };
          this.selectedRealData2Cell= { 'row': -1 , 'col': -1 };
          this.selectedRealData3Cell= { 'row': -1 , 'col': -1 };
          this.xSpreadSheetData= [{}];
          
          if(this.spreadsheet){
            
            this.spreadsheet.loadData([{}]);
            this.xlsxUpdated = false ;
          }
           

            this.viewState=  {
            isCanvasShow :true,
            isExcelShow :false,
            isSettingShow :false,
            isExcelFileMenuShow : false,
            isExcelSelectCellMenuShow:false,
            selectCellMode : false ,
            fullLengthCellSelected : false ,
            diameterCellSelected: false ,
            gpsCellSelected : false,
            DateTimeCellSelected:false,
            RealData1CellSelected:false,
            RealData2CellSelected:false,
            RealData3CellSelected:false,
            };
      },
     
    loadCurrentData (this:any){
           try{      
           // this.removeCurrentData();

//this.wipeCurrentData();

           const data=JSON.parse( ( window as any) . localStorage.getItem("RecentData") );
           
           if (data.filename) { this.filename = data.filename; }
          
           // if (data.acquiredData) {   this.acquiredData  = data.acquiredData ; } 
            
            if (data.xSpreadSheetData) {

                this.xSpreadSheetData  = data.xSpreadSheetData ;
                
                if(this.spreadsheet){
          
                  this.spreadsheet.loadData(this.xSpreadSheetData);
                    this.xlsxUpdated = true ;
                }
            }
            
            if (data.denshiShoKokuban){

                this.denshiShoKokuban=data.denshiShoKokuban;
            }
            if (data.recentlyAcquiredData){

                this.recentlyAcquiredData=data.recentlyAcquiredData;
            }
            if (data.selectedFullLengthCell) {  this.selectedFullLengthCell = data.selectedFullLengthCell ; }
            if (data.selectedDiameterCell) {  this.selectedDiameterCell = data.selectedDiameterCell ; }
            if (data.selectedGPSCell) {   this.selectedGPSCell = data.selectedGPSCell ; }
            if (data.selectedDateTimeCell) {   this.selectedDateTimeCell = data.selectedDateTimeCell ; }
            if (data.selectedRealData1Cell) {   this.selectedRealData1Cell = data.selectedRealData1Cell ; }
            if (data.selectedRealData2Cell) {   this.selectedRealData2Cell = data.selectedRealData2Cell ; }
            if (data.selectedRealData3Cell) {   this.selectedRealData3Cell = data.selectedRealData3Cell ; }
            if (data.isMeasuring){ 

              this.isMeasuring = data.isMeasuring;
            }

            if (data.viewState){  
              
              for (const key in data.viewState) {
                if (Object.prototype.hasOwnProperty.call(data.viewState, key)) {
                 
                   this.viewState[key] = data.viewState[key];
                }
              }
            }
            
          }catch (e){ console.error(e); }
      },
       
     saveCurrentData(this:any){
      

      ( window as any) . localStorage.setItem(
        "RecentData" ,
        JSON.stringify( {
             denshiShoKokuban:this.denshiShoKokuban,
            isMeasuring : this.isMeasuring,
            filename : this.filename ,
            recentlyAcquiredData:this.recentlyAcquiredData,
           // acquiredData : this.acquiredData ,  
            xSpreadSheetData :    this.xSpreadSheetData ,//this.spreadsheet.getData(),
            selectedFullLengthCell: this.selectedFullLengthCell ,
            selectedDiameterCell: this.selectedDiameterCell ,
            selectedGPSCell:  this.selectedGPSCell ,
            DateTimeCellSelected: this.DateTimeCellSelected,
            selectedDateTimeCell: this.selectedDateTimeCell,
            RealData1CellSelected: this.RealData1CellSelected,
            RealData2CellSelected: this.RealData2CellSelected,
            RealData3CellSelected: this.RealData3CellSelected,
            selectedRealData1Cell:this.selectedRealData1Cell,
            selectedRealData2Cell:this.selectedRealData2Cell,
            selectedRealData3Cell:this.selectedRealData3Cell,
             viewState : this.viewState
        }));
          
      },
  
      gpsLink (this:any){
            if (window.confirm("位置情報の確認 外部リンクを開きますか？ ")){
                 window.open("https://www.google.co.jp/maps/@"+this.gps.lat+","+this.gps.lon+",15z", '_blank');
          }
      },
      raitoValue(v:number, calibrationLongLengthMeter : number , calibrationShortLengthMeter :number ){
         if(0.5 < v){
             return v/ calibrationLongLengthMeter ;
         } 
         return v /  calibrationShortLengthMeter;
      }, 
       addValue(v:number, calibrationLongLengthMeter : number , calibrationShortLengthMeter :number ){
        if(0.5 < v){
             return calibrationLongLengthMeter -v;
         } 
         return  calibrationShortLengthMeter -v;  
      },
      clearCalibration (this:any){

            this.calibration.serial1Value=0;
            this.calibration.serial2Value=0;
            this.calibration.serial3Value=0;

      },
      async calibrationStart( this:any ){

          this.clearCalibration();
          await this.measurement();
          
      },
      async calibrationAdd (this:any){
            this.calibration.use = "add";

            if( 0.5< this.serial1Value){
                  this.calibration.serial1Value = this.calibrationLongLengthMeter - this.serial1Value  ;      
            } else {
                  this.calibration.serial1Value =  this.calibrationShortLengthMeter - this.serial1Value  ;           
            }
            if( 0.5< this.serial2Value){
                  this.calibration.serial2Value = this.calibrationLongLengthMeter -this.serial2Value  ;      
            } else {
                  this.calibration.serial2Value = this.calibrationShortLengthMeter - this.serial2Value  ;           
            }
            
           if( 0.5< this.serial3Value){
                  this.calibration.serial3Value = this.calibrationLongLengthMeter - this.serial3Value  ;      
            } else {
                  this.calibration.serial3Value =  this.calibrationShortLengthMeter - this.serial3Value ;           
            }
           
            this.saveCalib();
            
            // await this.initSettings();
           alert( "設定しました。" );
      },
      calibrationRatio (this:any){
            this.calibration.use = "ratio";
        
            if( calibrationLongLengthMeterThreshold < this.serial1Value){
                  this.calibration.serial1Value = this.serial1Value /  this.calibrationLongLengthMeter   ;      
            } else {
                  this.calibration.serial1Value = this.serial1Value /  this.calibrationShortLengthMeter ;           
            }
            if( calibrationLongLengthMeterThreshold < this.serial2Value){
                  this.calibration.serial2Value = this.serial2Value / this.calibrationLongLengthMeter   ;      
            } else {
                  this.calibration.serial2Value = this.serial2Value /  this.calibrationShortLengthMeter ;           
            }
            
           if( calibrationLongLengthMeterThreshold < this.serial3Value){
                  this.calibration.serial3Value = this.serial3Value /  this.calibrationLongLengthMeter  ;      
            } else {
                  this.calibration.serial3Value = this.serial3Value /  this.calibrationShortLengthMeter ;           
            }
             alert( "設定しました。" );
            
      },
      ceilDemicalPoint (v:any , unit :number ){
  
       var s =String(v);
       var ss = s.split('.');
       if(1 < ss.length){
           
           var u:any = "";
           for ( var i=0; i< ss[1].length ; i ++ ){
               if( i < unit ){
                 u += ss[1][i];
               }
           } 
       }else {
        return s;
       }
       if(u===undefined) { u = "0"; }
       return ss[0] +"." + u;
      /* 
      桁溢れするので↑の実装..
       const u = (10**unit);
        var r = v * u;
       console.log(v);
                console.log(r);
      
         r = Math.ceil(v) 
        return r / u ;  
        */
      },
      exchangeToColName( num : number ){
                      return ["","A","B","C","D","E","F","G","H","I","J","K","L","M","N","O", "P","Q","R","S","T","U","V","W","X","Y","Z"][num];
        } ,
       async showArea(this : { viewState:any } ,area:string){
        
        this.viewState.isCanvasShow = false;
        this.viewState.isExcelShow = false;
        this.viewState.isSettingShow = false;
        switch( area ){
            case 'Canvas' :
               
               
                this.viewState.isCanvasShow = true;
               // await new Promise(s => setTimeout(s, 1500))
               
            break;
            case 'Excel':
                this.viewState.isExcelShow = true;              
            break;
            case 'Setting':
                 this.viewState.isSettingShow = true;
            break;
        }  
        
    },
    showExcelMenu (this:{ viewState:any},state:string){
        
        switch(state){
            case 'File' :
             this.viewState.isExcelSelectCellMenuShow = false;
             this.viewState.isExcelFileMenuShow = !this.viewState.isExcelFileMenuShow ;
            break;
            case 'CellSelection': 
            this.viewState.isExcelFileMenuShow = false ;
            this.viewState.isExcelSelectCellMenuShow = !this.viewState.isExcelSelectCellMenuShow;
            break;
      }
    },
        onDebugMode(this:any){
        this.debugModeCount ++  ;
        if ( 29 < this.debugModeCount  ){
            this.isDebugMode=true;
        }
    },
    selectCircle(this:any, modeOn = true ){
      
        //alert(this.cameraOutput.width);
        this.selectCircleMode = modeOn;

        if ( !this.circles  ){ return ; }
        if ( !this.circles.cols  ){ return ; }
    
         const dispCircleCtx =   this.$refs.dispCircleCanvas.getContext('2d');

        let src = cv.imread(   this.cameraInput  );// this.cameraInput);  
        let dst = new cv.Mat.zeros(src.rows, src.cols, cv.CV_8UC4);
        // dst.setTo(new cv.Scalar(0,10,0,1));

     for (let i = 0; i < this.circles.cols; ++i) {   
             let x = this.circles.data32F[i * 3] + this.colWidth;
            let y = this.circles.data32F[i * 3 + 1] + this.rowHeight;
            let radius = this.circles.data32F[i * 3 + 2];
            let center = new cv.Point(x, y);
             //cv.circle(dst, center, radius, white);
            cv.circle(dst, center, radius, nextCirclecol);
        }
        selectedCircleNo = ( selectedCircleNo >= this.circles.cols-1  ) ? 0 : selectedCircleNo + 1 ;
       
        this.currentCircle.radius = this.circles.data32F[selectedCircleNo * 3 + 2];
        this.currentCircle.center = new cv.Point(
            this.circles.data32F[selectedCircleNo * 3] + this.colWidth , // x
            this.circles.data32F[selectedCircleNo * 3 + 1]  + + this.rowHeight // y
        );
        
        cv.circle(dst,  this.currentCircle.center,  this.currentCircle.radius, green,2);   
        cv.imshow( this.cameraOutput, dst);

     const cw = this.cameraOutput.width/2;
     const ch = this.cameraOutput.height/2;
     dispCircleCtx.clearRect(0, 0, cw, ch);
      dispCircleCtx.drawImage(
         this.$refs.canvasOutput, 
        0, 0, this.cameraOutput.width, this.cameraOutput.height,
        0, 0, cw, ch );

        
        src.delete();
        dst.delete(); 
        
        this.calculateDiameter();

      //***************************************************//zzt_240626
        
      //find the circle area from overlapped circles--> compare radii, cen_to_cen > rad--> find overlapArea
      let center_i = this.currentCircle.center ;    // get the selected circle center information 
      
      let nextCenter = null;  //get the next circle's center as well
      let nextRadius = 0 ; 
      if (this.circles.cols === 2) {
        let nextCircleNo = (selectedCircleNo >= this.circles.cols - 1) ? 0 : selectedCircleNo + 1;
        nextCenter = new cv.Point(
            this.circles.data32F[nextCircleNo * 3] + this.colWidth, // x
            this.circles.data32F[nextCircleNo * 3 + 1] + this.rowHeight // y
        );
        nextRadius = this.circles.data32F[nextCircleNo * 3 + 2];
        //console.log('nextRadius:', nextRadius);
    }  //cols === 2
     
     // Calculate the real-world distance between the two centers
     
     if(null===nextCenter){return;}
     
     const pixelDistance = Math.sqrt(
        Math.pow(center_i.x - nextCenter.x, 2) +
        Math.pow(center_i.y - nextCenter.y, 2)
    );
    
    if ( this.nonOverlapCircleAreaEnabled ){
       
      const radiusDifference = Math.abs(this.currentCircle.radius - nextRadius); // Compare the two radii 
      if (radiusDifference <= 10) {
          this.calculateRealWorldDistance(pixelDistance);  //to find nonOverlapArea
      }
    }/* else {
        console.log('radiusDifference more than 10 units.', radiusDifference);
    }*/
    //****************************************************//zzt_240626
        
},
    
calculateRealWorldDistance( pixelDistance:number ){ 
   if(touch_theta <0){ return ; }
  
   let thea_rad = ( ( touch_theta) * Math.PI / 180 ) ;
   let centers_dis =   touch_distance * Math.tan ( thea_rad  ) ; 
   if( centers_dis < 0 ){ return ;}
     
   let cen_to_cen =  centers_dis * 1.05; 
  
   const nonOverlapArea = this.calculateCircleArea(cen_to_cen);
   (this as any).nonOverlapCircleArea = nonOverlapArea;
   //console.log("nonOverlapArea", nonOverlapArea);
   //alert(nonOverlapArea); 
},
calculateCircleArea(  this: any, cen_to_cen: number ){  //zzt_240626 //find the area of non-overlap area of circle
  //console.log("calculate overlap circle area");
  
  let each_radius = this.diameter / 2.0 ;
  if (cen_to_cen >= 2 * each_radius) {
    // No overlap
    //return Math.PI * each_radius * each_radius;   //whole circle area
    return 0; 
  } else if (cen_to_cen === 0) {
    // Complete overlap
    return 0;
  } else {
    // Partial overlap
    const overlapArea = (2 * each_radius * each_radius * Math.acos(cen_to_cen / (2 * each_radius))) - (0.5 * cen_to_cen * Math.sqrt(4 * each_radius * each_radius - cen_to_cen * cen_to_cen));
    const totalCircleArea = Math.PI * each_radius * each_radius;
    const nonOverlapArea = totalCircleArea - (overlapArea / 2);
    return nonOverlapArea;
  } 
},//calculateCircleArea  zzt_240626  

touchSetDetectingPoint(
  // this : { dispCanvas: HTMLCanvasElement}, 
  this :any , 
  event: MouseEvent | TouchEvent) {  //zzt 240620

     const canvas = this.$refs.dispOverlayCircleCanvas;
   
     //event.preventDefault(); // Prevent default behavior for touch events
      if (event instanceof TouchEvent) {
        const touch = event.touches[0];
        this.handleCanvasTouch(touch.clientX, touch.clientY, canvas);
      } else if (event instanceof MouseEvent) {
        this.handleCanvasTouch(event.clientX, event.clientY, canvas);
      }
      
    },
handleCanvasTouch(this: any, clientX: number, clientY: number, canvas: HTMLCanvasElement) {   
      
      //const rect = this.$refs.canvas.getBoundingClientRect();
      const rect = canvas.getBoundingClientRect();
      const x = clientX - rect.left;
      const y = clientY - rect.top;

      overlayX = x;
      overlayY = y;
      //initial 4cm
      //const realWorldRadius =  this.scaleRealWorldDiameter*10/ 2.0; // Real-world radius in mm

      let realWorldRadius;
      //const detectedPipeRadius = (this.diameter - 2.5) / 2;
      const detectedPipeRadius = this.diameter / 2;
      
      if(detectedPipeRadius > 20.0){
          realWorldRadius = detectedPipeRadius;
          dynamicRadiusPixel = this.calculateRadiusInPixel(detectedPipeRadius, canvas); // Calculate pixel radius
      }else{
          realWorldRadius = this.scaleRealWorldDiameter*10/ 2.0;
          dynamicRadiusPixel = this.calculateRadiusInPixel(realWorldRadius,canvas);  //rad in pixel

      }
      if(adjustedDynamicRadiusPixel > 0){
          dynamicRadiusPixel = adjustedDynamicRadiusPixel ; 
      }
      const radiusMM = this.findRealWorldRadius(dynamicRadiusPixel); // Calculate pixel radius
      const converted_rad = 2.0 * radiusMM ;
      //alert(" ");
       
      this.drawCircleImage(
        x,
        y, 
        dynamicRadiusPixel,
        canvas
      );
},

calculateRadiusInPixel(this: any, realWorldRadius: number,canvas:HTMLCanvasElement) {
    //let cameraOutputWidth = this.cameraOutput.width || 560; // Replace with actual width if defined elsewhere
    //let cameraOutputWidth = canvas.width || 560;
    
    let cameraOutputWidth = screen_width;  //this.videoWidth
    let theta_rad = Math.atan(realWorldRadius / touch_distance);
    let currentCircleRadiusPixel = 0;
    if(touch_distance){
      let theta_deg = theta_rad * (180 / Math.PI);
      currentCircleRadiusPixel = (theta_deg / hov) * cameraOutputWidth; // In pixels
        
    }
    return currentCircleRadiusPixel;
}, 
findRealWorldRadius(this: any, currentCircleRadiusPixel: number) {
    //let cameraOutputWidth = screen_width || canvas.width;  // Use screen width or canvas width
    let cameraOutputWidth = screen_width   
    let cameraHFOV = hov;  // Horizontal field of view in degrees

    // Step 1: Convert pixels to degrees (theta)
    let theta_deg = (currentCircleRadiusPixel / cameraOutputWidth) * cameraHFOV;

    // Step 2: Convert degrees to radians
    let theta_rad = theta_deg * (Math.PI / 180);

    // Step 3: Find the real-world radius in mm using inverse tangent
    let realWorldRadius = Math.tan(theta_rad) * touch_distance;
    

    return realWorldRadius;  // The real-world radius in mm
},

drawCircleImage(this:any, x: number, y: number, dynamicRadiusPixel: number, canvas: HTMLCanvasElement) {
      const ctx = canvas.getContext('2d');
      let img_width = 2.0 * dynamicRadiusPixel;
      const clearTime = 3000;
      //this.circleImage.onload = () => {
        if (ctx) {

          ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous drawings
          ctx.save(); 

          ctx.drawImage(this.circleImage, x - dynamicRadiusPixel, y - dynamicRadiusPixel, img_width, img_width); //draw at top-left corner of image POS

          // Draw the converted radius text 
          /*
          ctx.font = "16px Arial";  // Set the font for the text
          ctx.fillStyle = "red";  // Set the text color
          let textX = x - dynamicRadiusPixel + 10;  // X position (left side of the image)
          let textY = y - dynamicRadiusPixel - 10;
          ctx.fillText(`変換された直径:${converted_rad.toFixed(3)} mm`, textX, textY);
          */

          if ( clearCircleScaleTimerID ){
              clearTimeout(clearCircleScaleTimerID)
          }
          clearCircleScaleTimerID =setTimeout( ()=>{ ctx.clearRect(0, 0, canvas.width, canvas.height);  } ,clearTime );          
        }
      //};
      // Store the current position for future redraws when radius changes
      overlayX = x;
      overlayY = y;
      global_canvas = canvas ;
},

selectDetectedCircle(this:any,num:number ){
     //   this.selectCircleMode = true;
        CircleDetectionSetting.currentNum = num ;
        this.selectedCircleDetectionMode=num;
        this.startCircleDetection(); //detectCircle();
        this.calculateDiameter();
},
    
calculateDiameter(  this: any ){

  if(this.avgLength){

    screen_width = this.videoWidth ;

    adjustedDynamicRadiusPixel = 0; //reset adjusted radius

    const LeaserStartingPoint =  this.DiameterCorrectionValue.detectStartPoint ;
    
    if (screen_width ==0){ return ;}

    const destAngle = ( ( (this.currentCircle.radius  ) / screen_width  /*554.25*/ /*this.cameraOutput.width*/ ) * (hov) ) ;   //zzt updated 560 to this.cameraOutput.width
    touch_theta = destAngle ;

    if(destAngle <0){ return ; }

    //use in overlay image
    touch_distance = (  ( this.avgLength - this. calibrationA ) /this. calibrationB  ) + LeaserStartingPoint  ;  
    
    const rad = ( ( destAngle) * Math.PI / 180 ) ;
    //d: diameter
    const d =   (((  ( this.avgLength - this. calibrationA ) /this. calibrationB  ) + LeaserStartingPoint ) * Math.tan ( rad  ))*2 ;

    if( d < 0 ){ return ;}
    this.diameter =   d * 1.05; // (d* this.DiameterCorrectionValue.coefficient) * / diameterCoefficient;
    if(this.diameter!==0){
        this.diameter =  this.diameter  + 2.5;
    }
        
  }//if(this.avgLength){
     
},
xlsxFunc  (this:any , cell: any, row: number, col: number){

          if ( this.viewState.selectCellMode ){
          
          if(! this.viewState.DateTimeCellSelected ){

          
                  if (window.confirm("日時のセルを"  + this.exchangeToColName(col+1) +""+ (row+ 1)+ "に設定しますか？")){
                      
                      this.selectedDateTimeCell.row = row;
                      this.selectedDateTimeCell.col = col;
                      this.viewState.DateTimeCellSelected = true;
                      return;
                   }
              } else 
              if( !this.viewState.fullLengthCellSelected){

                  
                  if (this.selectedDateTimeCell.row == row &&
                    this.selectedDateTimeCell.col ==col ){
                    return;
                 }
                  if (window.confirm("削孔長のセルを"  + this.exchangeToColName(col+1) +""+ (row+ 1)+ "に設定しますか？")){
                      this.selectedFullLengthCell.row = row;
                      this.selectedFullLengthCell.col = col;
                     this.viewState.fullLengthCellSelected = true;
                     return;
                  }
              } else
              if(! this.viewState.diameterCellSelected){
                   
                  if (this.selectedFullLengthCell.row == row &&
                    
                    this.selectedFullLengthCell.col ==col ){
                    return;
                 }

                  if (window.confirm("削孔径のセルを"  + this.exchangeToColName(col+1) +""+ (row+ 1)+ "に設定しますか？")){
                      
                      this.selectedDiameterCell.row = row;
                      this.selectedDiameterCell.col = col;
                      this.viewState.diameterCellSelected = true;
                      return;
                   }
              } else 
              if(! this.viewState.RealData1CellSelected ){

                  if (this.selectedDiameterCell.row == row &&
                    this.selectedDiameterCell.col ==col ){
                    return;
                 }

                  if (window.confirm("一番大きいセンサ値のセルを"  + this.exchangeToColName(col+1) +""+ (row+ 1)+ "に設定しますか？")){
                      
                      this.selectedRealData1Cell.row = row;
                      this.selectedRealData1Cell.col = col;
                      this.viewState.RealData1CellSelected = true;
                      return;
                   }
              }else 
              if(! this.viewState.RealData2CellSelected ){

                  if (this.selectedRealData1Cell.row == row &&
                    this.selectedRealData1Cell.col ==col ){
                    return;
                 }

                  if (window.confirm("2番目に大きいセンサ値のセルを"  + this.exchangeToColName(col+1) +""+ (row+ 1)+ "に設定しますか？")){
                      
                      this.selectedRealData2Cell.row = row;
                      this.selectedRealData2Cell.col = col;
                      this.viewState.RealData2CellSelected = true;
                      return;
                   }
              }else 
              if(! this.viewState.RealData3CellSelected && !this.isGpsUsed ){

                  if (this.selectedRealData2Cell.row == row &&
                    this.selectedRealData2Cell.col ==col ){
                    return;
                 }

                  if (window.confirm("3番目に大きいセンサ値のセルを"  + this.exchangeToColName(col+1) +""+ (row+ 1)+ "に設定しますか？")){
                      
                      this.selectedRealData3Cell.row = row;
                      this.selectedRealData3Cell.col = col;
                      this.viewState.RealData3CellSelected = true;
                       this.viewState.selectCellMode = false;
                      return;
                   }
              }
              
              else 
               if(! this.viewState.gpsCellSelected){

                if(this.isGpsUsed){
                
                  if (this.selectedFullLengthCell.row == row &&
                    this.selectedFullLengthCell.col ==col ){
                  return;
                 }
                 
                if (this.selectedDiameterCell.row == row &&
                  this.selectedDiameterCell.col ==col ){
                  return;
                }

                if (window.confirm("位置情報のセルを"  + this.exchangeToColName(col+1) +""+ (row+ 1)+ "に設定しますか？")){
                      this.selectedGPSCell.row = row;
                      this.selectedGPSCell.col = col;
                      this.viewState.gpsCellSelected = true;
                      this.viewState.selectCellMode = false;
                       return;
                   }     
              }
               }
              
            }
},
  setXlsxFunc (this:any){
      
            this.spreadsheet.on('cell-selected', this.xlsxFunc );   
  },
  exportXlsx(this : any ){
           const filename = window.prompt( "ファイル名を入力して下さい。");
           if(filename == "" || filename == null ){ return ; }
           
           
           const new_wb = xtos( this.spreadsheet.getData());

          XLSX.writeFile(new_wb, filename+".xlsx");
       },
    async saveXlsx(this :any){
           if( this.filename == "" || this.filename == null ){ 
                 this.$emit('show-flash',{"message":"ファイル名がありません。","type": "warning"});          
                return ;
            }
           
           const new_wb = xtos( this.spreadsheet.getData());
              
            try{
               
            var wbout = XLSX.write(new_wb, {
                bookType:'xlsx',
                bookSST:true,
                type:'base64'
            });
        
         const r = await axios.post (process.env.VUE_APP_API_URL +"Base64DataUploadHttpTrigger/"+ process.env.VUE_APP_API_CODE ,
          {data:wbout,filename: moment().format('YYYY/MM/DD/') +  this.filename  + ".xlsx"  });
   
          if(r.data.error==0){
              
               XLSX.writeFile(new_wb, this.filename+".xlsx");
                  this.$emit('show-flash',{"message":"ファイルを保存しました。","type": "success"});        
          } else {
               this.$emit('show-flash',{"message":"クラウドへのファイル保存に失敗しました。ネットワークを確認してください。","type": "danger"});  
              
               XLSX.writeFile(new_wb, this.filename+".xlsx");
        
                      
          }
          }catch(e :any ){
               this.$emit('show-flash',{"message":"クラウドへのファイルの保存に失敗しました。ネットワークを確認してください。","type": "danger"});      
              
                  XLSX.writeFile(new_wb, this.filename+".xlsx");      
          }
          
       },
        
  importXlsx(this :any,e:any){
           
      if (e.target instanceof HTMLInputElement) { 
        const file =  e.target.files[0];
        const reader : any = new FileReader();
        reader.onload = () => {
            
          const wb = XLSX.read(reader.result, { type: "binary" })
          this.xSpreadSheetData = stox(wb);

          this.SpreadSheetKey ++ ;
          this.xlsxUpdated = true ;
         // console.log(this.xSpreadSheetData);
          this.spreadsheet.loadData(this.xSpreadSheetData);
        }
         reader.readAsBinaryString(file);
      }  
  
       },   
        outsideClickEvent (this:{ viewState:any },e:any)  { 
            // alert(e.target.className);
              if(  e.target.className.indexOf( 'show-excel' ) ===  -1 ){  this. viewState. isExcelFileMenuShow = false;  }
          }
          ,selectCellMode( this:any ){
                alert("データを記入するセルを選択してください。\n日時\n削孔長\n削孔径\n一番大きいセンサ値\n二番目に大きいセンサの値\n三番目に大きいセンサの値\nの順に指定します。");

                // this.$emit('show-flash',{"message":"データを記入するセルを選択してください。","type": "success"});   
                this.showArea('Excel');
                this. viewState. isExcelFileMenuShow = false; 
                this.viewState.selectCellMode = true;   
                this.viewState.fullLengthCellSelected = false;
                this.viewState.diameterCellSelected = false;
                this.viewState.gpsCellSelected = (this.isGpsUsed) ? false:true;

                this.viewState.DateTimeCellSelected = false;
                this.viewState.RealData1CellSelected = false;
                this.viewState.RealData2CellSelected = false;
                this.viewState.RealData3CellSelected = false;
                


          
       } ,
   async  writeFile(this:any, fileHandle:any, contents:any) {
  // writable作成
  const writable = await fileHandle.createWritable();

  // コンテンツを書き込む
  await writable.write(contents);
  // ファイル閉じる
  await writable.close();
    },
           
        async startMeasurement( this:any ){
          
           if (  !this.filename || this.filename == ""){
                        
           this.filename = window.prompt( "ファイル名を入力して下さい。");
           if ( !this.filename || this.filename == "" ){
               
            // this.$emit('show-flash',{"message":"ファイル名をご入力してください。","type": "warning"});      
               return ; 
           }
         }
        if ( this.isMeasuring) {
            
         if ( window.confirm("本当に計測を終了しますか？")){
            
            
              this.saveXlsx();
              this.removeCurrentData();
              this.wipeCurrentData();
              this.saveCurrentData();
            

         } 
        
         return;
        }
        
         
  //          /*   this.filehandle = await ( window as any ).showSaveFilePicker({ types: [{  description: "Text Files", accept: { "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"[".xlsx"],},},] }); await this.writeFile(this.fileHandle, ""); */
         
        /*   if( 
                (  this.selectedDiameterCell.row === -1 && this.selectedDiameterCell.col === -1 ) ||
                this.selectedFullLengthCell.row === -1, this.selectedFullLengthCell.col === -1 || 
                this.selectedGPSCell.row === -1, this.selectedGPSCell.col === -1
            ){

              */
 
                this.selectCellMode();

            //    return ;
          //  }
             this.isMeasuring=true ;
    
            await this.measurement();
            
                 if(this.isMeasuring){
                     if(this.selectedDetectionMode.includes('circle') ){
          //this.startCircleDetection();
                }
          }
        },
        async uploadBase64Image (this:any,base64img:any,filename:string){
        
        try{
           const base64string = base64img.split('base64,')[1];     
           const  r  = await axios.post (process.env.VUE_APP_API_URL +"Base64DataUploadHttpTrigger/"+ process.env.VUE_APP_API_CODE ,
          {data: base64string ,filename:  filename }
            );
        
          if(r.data.error==0){
              
                  this.$emit('show-flash',{"message":"写真をアップロードしました。","type": "success"});
                  downloadBase64Image(base64img,filename);
          } else {
               
               this.$emit('show-flash',{"message":"写真のアップロードに失敗しました。ネットワークを確認してください。","type": "danger"});          
               downloadBase64Image(base64img,filename);
          }

          } catch(e :any ){
               
               this.$emit('show-flash',{"message":"写真をアップロードに失敗しました。ネットワークを確認してください。","type": "danger"});      
               
                  downloadBase64Image(base64img,filename);
          }
        },
        async measurement ( this : any ) {
          
            try{ if(  !( await this.serial1?.getInfoAsync()) ){ await this.serial1?.connect(); } } catch(er){ console.error(er); }
            try{ if( !( await this.serial2?.getInfoAsync()) ){ await this.serial2?.connect();} } catch(er){ console.error(er); }
            try{ if( !( await this.serial3?.getInfoAsync()) ){ await this.serial3?.connect();} } catch(er){ console.error(er); }
        
            try{
                                
                this.serialIntervalTaskFunc= setInterval( async ()=>  {
                       
                    if( this.isMeasurementStopped ){ return ; }
                    this.serial1?.setReaderLock();
                       
                    await this.serial1?.sendString("iSM\n");
                    var text1:any =  await this.serial1?.readString();  
                    
                    if( text1 !==""){
                    const arr1=convertSensorDataToArr( text1);
                    
                    for(let i = 0 ;  i < arr1.length ; i++){     
                         this.serial1Value = this.calibration[ this.calibration.use ] ( "serial1Value",  arr1[i] ); 
                    }
                    }
               
                    await this.serial1?.releaseReaderLock();
          
                    this.serial2?.setReaderLock();
                        
                    await this.serial2?.sendString("iSM\n");
                    var text2:any =  await this.serial2?.readString();  
                    if( text2 !==""){
                    const arr2 =convertSensorDataToArr( text2);
                    
                    for(let i = 0 ;  i < arr2.length ; i++){     
                         this.serial2Value = this.calibration[ this.calibration.use ] ( "serial2Value",  arr2[i] ); 
                    }
                    }

               
                    await this.serial2?.releaseReaderLock();
           
                    this.serial3?.setReaderLock();
                        
                    await this.serial3?.sendString("iSM\n");
                    var text3:any =  await this.serial3?.readString();  
                    if( text3 !==""){  
                    const arr3 =convertSensorDataToArr( text3);
                    
                    for(let i = 0 ;  i < arr3.length ; i++){     
                         this.serial3Value = this.calibration[ this.calibration.use ] ( "serial3Value",  arr3[i] ); 
                    }
                    }
                    await this.serial3?.releaseReaderLock();
    
                    const values = [  this.serial1Value * 1000 ,this.serial2Value*1000, this.serial3Value*1000 ].sort(
                        function (a, b) { return a - b }
                    );
           
                    const v1 =  values[0] ;
                    const v2 =  values[1] ;
                    var v3 = values[2];
                    this.realData1 = v1;
                    this.realData2 = v2;
                    this.realData3 = v3;

                    if (this.calibration. isLowerThanBothLengthChecked ){
                  
                        if (  excludeBothLengthMs  >=  v1 ){
                            this.avgLength = v1;
                            
                        }   
                        if (  excludeBothLengthMs  >=  v2 ){
                            this.avgLength = v2;
                        } else{
                             this.avgLength = ( ( v1 + v2)  /2 );
                        }  
                    } else {
                        
                        this.avgLength = ( ( v1 + v2)  /2 );
                    }
             
                    if(
                        v3 <= this.LengthCorrectionValue.center.thr 
                    ){
                
                        v3 = v3 +this.LengthCorrectionValue.center.add.above;
                    }else{
                
                        v3 = v3+ this.LengthCorrectionValue.center.add.below;
                    }
                     var fullLength = v3 - this.avgLength;
                    
                     if (this.calibrationCorrectionShortMeasurementsMillimetre != 0 &&
                         this.calibrationThresholdShortMeasurementsCentimeter >= fullLength ){
                          fullLength = fullLength -  this.calibrationCorrectionShortMeasurementsMillimetre /10 ;
                        }
                     this.fullLength = fullLength;
                     
                }  , this.measurementTimerMs );
                
               
              }catch(e){
                
                await this.serial1?.releaseReaderLock();
               await this.serial2?.releaseReaderLock();
               await this.serial3?.releaseReaderLock();
              }
        },
        async stop ( this : any , param  :any ) {
          if( !this.isMeasuring){return;}
          if (! this.filename ){
                       this.$emit('show-flash',{"message":"計測を開始してください。","type": "warning"});          
              return;
          } 
          
          this.showLoadingView(20000);
          
          this.isMeasurementStopped =true;
          this.$refs.cameraSound.play();
        
           const basefilename =  moment().format('YYYY/MM/DD/') + "接写/" +  this.filename + "-" + moment().format('YYYY-MM-DDTHH:mm:ss') ;
           const filename =  basefilename + ".jpg";
           if(this.useSubCamera){

              this.uploadSubCameraImage(basefilename.replace("接写/","") );
           }
          this.enableDiameter = param.diameter ;
        
             const context:any = document.createElement('canvas').getContext('2d')
             //zzt
             if(!this.enableDiameter){
                 
                this.cameraOutput .getContext("2d").clearRect(0, 0, this.cameraOutput.width, this.cameraOutput.height);
             }
             
            var lastdata :any = {};
             
    //try{
      
            const img1 = new Image();
            await new Promise((resolve, reject) => {
                img1.onload = () => resolve(img1);
                img1.onerror = (e) => reject(e);
                img1.src =  this.$refs.canvasOutput.toDataURL();
            })
                        
            const img2 = new Image();    
            await new Promise((resolve, reject) => {
                img2.onload = () => resolve(img2);
                img2.onerror = (e) => reject(e);
                img2.src =  this.$refs.canvas.toDataURL();
            })
            
            context.canvas.width = this.$refs.canvas.width;
            context.canvas.height = this.$refs.canvas.height;
            
            
            context.drawImage(img2,0,0)
            context.drawImage(img1,0,0)
            context.drawImage( this.$refs.denshiShoKokubanCanvas , 0, 0);
            var base64img = context.canvas.toDataURL('image/jpeg', 1.0);
            
            if(this.isGpsUsed){
              if ( this.gps.lat && this.gps.lon ){
                const exifObj = piexif.load(base64img);
        
                exifObj.GPS[piexif.GPSIFD.GPSLatitude] =  degToDmsRational( this.gps.lat );
                exifObj.GPS[piexif.GPSIFD.GPSLatitudeRef] =  "N";
                exifObj.GPS[piexif.GPSIFD.GPSLongitude] =  degToDmsRational( this.gps.lon );
                exifObj.GPS[piexif.GPSIFD.GPSLongitudeRef] =  "W";

                const exifbytes = piexif.dump(exifObj);
                base64img = piexif.insert(exifbytes, base64img);
              } 
            }
            
            const base64string = base64img.split('base64,')[1];       
            
            
            await this.uploadBase64Image (base64img,filename);
        //   const  r  = await axios.post (process.env.VUE_APP_API_URL +"Base64DataUploadHttpTrigger/"+ process.env.VUE_APP_API_CODE ,
       //   {data: base64string ,filename:  filename }
       //     );
        /*
          if(r.data.error==0){
              
                  this.$emit('show-flash',{"message":"写真をアップロードしました。","type": "success"});
                  downloadBase64Image(base64img,filename);
          } else {
               
               this.$emit('show-flash',{"message":"写真のアップロードに失敗しました。ネットワークを確認してください。","type": "danger"});          
               downloadBase64Image(base64img,filename);
          }

          }catch(e :any ){
               
               this.$emit('show-flash',{"message":"写真をアップロードに失敗しました。ネットワークを確認してください。","type": "danger"});      
               
                  downloadBase64Image(base64img,filename);
          }
      */
      
      /*
           lastdata.img = {  name : filename , base64 :  base64img };
        */    
            const sheet = this.spreadsheet;
            
             sheet.cellText( 
                this.selectedFullLengthCell.row , 
                this.selectedFullLengthCell.col ,
                this.fullLength  // this.ceilDemicalPoint ( this.fullLength * 0.001 ,3 )
              ).reRender();
            
            if (this.nonOverlapCircleAreaEnabled){
            sheet.cellText(this.selectedDiameterCell.row, 
            this.selectedDiameterCell.col , 
            this.nonOverlapCircleArea).reRender(); 
            }
            if(this.enableDiameter){
                
              sheet.cellText( 
                  this.selectedDiameterCell.row , 
                  this.selectedDiameterCell.col ,
                  this.diameter  //  this.ceilDemicalPoint ( this.diameter * 0.1 ,1 )
               ).reRender();      
            }

             sheet.cellText( 
                this.selectedDateTimeCell.row , 
                this.selectedDateTimeCell.col ,
                new Date().toLocaleString('ja-JP', {
                timeZone: 'Asia/Tokyo',
              }) // this.ceilDemicalPoint ( this.fullLength * 0.001 ,3 )
              ).reRender();
            
              sheet.cellText( 
                this.selectedRealData1Cell.row , 
                this.selectedRealData1Cell.col ,
                this.realData1  // this.ceilDemicalPoint ( this.fullLength * 0.001 ,3 )
              ).reRender();
            
              sheet.cellText( 
                this.selectedRealData2Cell.row , 
                this.selectedRealData2Cell.col  ,
                this.realData2  // this.ceilDemicalPoint ( this.fullLength * 0.001 ,3 )
              ).reRender();

                sheet.cellText( 
                this.selectedRealData3Cell.row , 
                this.selectedRealData3Cell.col  ,
                this.realData3 // this.ceilDemicalPoint ( this.fullLength * 0.001 ,3 )
              ).reRender();
            


            if(this.isGpsUsed){
            sheet.cellText( this.selectedGPSCell.row , this.selectedGPSCell.col ,
                 this.gps.lat 
            ).reRender();    
             sheet.cellText( this.selectedGPSCell.row , this.selectedGPSCell.col+1 ,
               this.gps.lon
            ).reRender();      
            }
            
            this.selectedFullLengthCell.row = this.selectedFullLengthCell.row + 1 ;
            this.selectedDiameterCell.row  = this.selectedDiameterCell.row  + 1 ;
            this.selectedDateTimeCell.row= this.selectedDateTimeCell.row+1;
            this.selectedRealData1Cell.row =this.selectedRealData1Cell.row +1;
            this.selectedRealData2Cell.row =this.selectedRealData2Cell.row +1;
            this.selectedRealData3Cell.row =this.selectedRealData3Cell.row +1;

            
            lastdata.fullLength = { col :  this.selectedFullLengthCell.col , row : this.selectedFullLengthCell.row ,  data : this.fullLength  } as any;
              lastdata.diameter = {  col :  this.selectedDiameterCell.col ,  row :   this.selectedDiameterCell.row ,  data : this.diameter   } as any;
              
              if(this.nonOverlapCircleAreaEnabled){
              lastdata.nonOverlapCircleArea = { col: this.selectedDiameterCell.col, row: this.selectedDiameterCell.row, data: this.nonOverlapCircleArea } as any;
              }
            if(this.isGpsUsed){
            
            this.selectedGPSCell.row  = this.selectedGPSCell.row  + 1 ;   
            
              lastdata.gps = {} as any ;
              lastdata.gps.lat  = {  col :  this.selectedGPSCell.col ,  row :   this.selectedGPSCell.row ,  data :  this.gps.lat    } as any;
              lastdata.gps.lat  = {  col :  this.selectedGPSCell.col ,  row :   this.selectedGPSCell.row ,  data :  this.gps.lon    } as any;
            }

              if (this. recentlyAcquiredDataMax <= this.recentlyAcquiredData.length-1 ){
                  this.recentlyAcquiredData.splice(0, 1) ;
              }   
             this.recentlyAcquiredData.push(lastdata);
              
            //  this.recentlyAcquiredData.push(lastdata);
              
            // this.acquiredData.push(lastdata);
              
          //   await this.saveXlsx();   
             this.isMeasurementStopped =false;
             // this.showArea('Excel');

            this.xSpreadSheetData= this.spreadsheet.getData();
            this.saveCurrentData();
             
          this.isLoading=false;
        }    
       
    }
    
}

